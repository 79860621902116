'use strict';

const feedControl = {
    notifications: [],
    index: 0,
    timeoutID: null,
    isNotificationVisible: false,
    isRunning: false,
    restart: function () {
        this.isRunning = true;
        this.displayNext();
    },
    stop: function () {
        this.isRunning = false;
        clearTimeout(this.timeoutID);
    },
    getNext: function () {
        const notification = this.notifications[this.index];
        this.index = (this.index + 1) % this.notifications.length;
        return notification;
    },
    displayNext: function () {
        if (this.isRunning 
                && !this.isNotificationVisible) {
            this.isNotificationVisible = true;
            let next = this.getNext();
            if (next) {
                this.isNotificationVisible = true;
                displayNotification(next, () => { this.isNotificationVisible = false; this.displayNext(); });
            } else {
                this.timeoutID = setTimeout(() => this.displayNext(), 5000);
                this.isNotificationVisible = false;
            }
        }
    },
    fetchFeed: function () {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://app.geektastic.com/public/news-feed');
        xhr.onload = () => {
            if (xhr.status === 200) {
                this.notifications = JSON.parse(xhr.responseText);
                this.index = 0;
            }
            else {
                console.error('Could not fetch news feed. Call returned ' + xhr.status);
            }
        };
        xhr.onerror = () => {
            console.error('Could not fetch news feed.');
            
        };
        xhr.send();
    },
    start: function () {
        this.isRunning = true;
        this.fetchFeed();
        this.displayNext();
        // re-fetching works but it is not needed
        // setInterval(() => {this.fetchFeed(); if(!this.isRunning){this.displayNext();} }, 60000);
    }
};

feedControl.start();

function displayNotification(notification, onRemove) {
    notification.visibleFor = 8000;
    const animationTime = 1000;
    const backOffTime = 1000;

    const expressPassed = createExpressPassedPanel(notification);

    document.getElementById("news-feed").appendChild(expressPassed);

    const slideUp = () => expressPassed.classList.add("slide-up");;
    const remove = () => {
        expressPassed.remove();
        setTimeout(onRemove, backOffTime);
    };
    const slideDown = () => {
        if (expressPassed.matches(":hover")) {
            setTimeout(slideDown, 1000);
        } else {
            expressPassed.classList.remove("slide-up");
            expressPassed.classList.add("slide-down");
            setTimeout(remove, animationTime);
        }
    };

    setTimeout(slideUp, 50);
    setTimeout(slideDown, notification.visibleFor + animationTime);
}

function createExpressPassedPanel(notification) {
    const template = document.getElementById("express-callenge-passed").content.firstElementChild.cloneNode(true);
    template.$ = function (selector) {
        return this.querySelector(selector);
    };

    const image = template.$(".express-callenge-passed__language-logo");
    image.src = "/assets/images/logos/programming-languages/" + notification.language + ".svg";
    image.alt = notification.language;
    image.loading = "lazy";
    image.width = "64";
    image.height = "64";

    template.$(".express-callenge-passed__topic").textContent = notification.topic;
    template.$(".express-callenge-passed__first-name").textContent =
        notification.firstname + (notification.location ? ", " : "");

    if (notification.location) {
        template.$(".express-callenge-passed__location").textContent = notification.location;
    }

    if (notification.top != null && notification.top <= 20) {
        const threshold = Math.floor(Math.max(notification.top - 0.01, 0) / 5) * 5 + 5;
        template.$(".express-callenge-passed__top-geek").textContent = `Geektastic: Top ${threshold}%`;
    } else {
        template.$(".express-callenge-passed__top-geek").remove();
    }

    template.$(".express-callenge-passed__timestamp").textContent = computeTimeDiff(notification.timestamp);
    return template;
}

function computeTimeDiff(timestamp) {
    const DAY = 24 * 60 * 60, HOUR = 60 * 60, MINUTE = 60;
    const currentTime = new Date().getTime();
    const secondsDiff = Math.floor((currentTime - timestamp) / 1000);
    let unit = "second";
    let quantity = 0;
    if (secondsDiff > DAY) {
        unit = "day";
        quantity = Math.floor(secondsDiff / (DAY));
    } else if (secondsDiff > HOUR) {
        unit = "hour";
        quantity = Math.floor(secondsDiff / (HOUR));
    } else if (secondsDiff > MINUTE) {
        unit = "minute";
        quantity = Math.floor(secondsDiff / (MINUTE));
    } else {
        unit = "second";
        quantity = secondsDiff;
    }
    if (quantity > 1) {
        unit += "s";
    }
    return `${quantity} ${unit} ago`;
}